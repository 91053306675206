import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import RollCallVid from "../../videos/jjacks.mp4"
import VideoWrap from '../../components/project_page/image_wrappers/VideoWrap';

import globalStyles from '../global.module.css';

const headerBackground = "#f4ffe1"
const navColor="#222";
const Pivot = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="The Pivot"
      link="https://the-pivot.com"
      oneLiner="Doing cool things together"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Collaboration"
          headline={`The platform where professional women athletes connect with brands`}
          content={`
            We worked with The Pivot to develop a web app, landing page and
            visual identity to drive unique collaborations between professional athletes and
            innovative companies.
          `}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomNormal}`}>
        <Web1
          color={headerBackground}
          desktopImage={<Img fluid={data.body6.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body4.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          color={headerBackground}

          desktopImage={<Img fluid={data.body7.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body5.childImageSharp.fluid} />}
        />
      </div>

      <div className={`${globalStyles.marginBottomBig}`}>
        <div className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}>
          <div className={`${globalStyles.flexGrow} ${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}>
            <TextBlock
              title="Design"
              headline={`Bold and different`}
              content={<><p>The Pivot wanted to accentuate the strength and boldness of
                athletes on the field while incorporating their unique, varied, and barrier-breaking
                personas off.</p><p>We collaborated with them to create a visual identity that
                embodied these attributes while avoiding reading as overtly sporty or feminine.</p><p>
                </p></>
              }
            />
          </div>
          <div className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}>
            <Image1 img={<Img fluid={data.body8.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Pivot

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/pivot/header.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/pivot/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/pivot/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/pivot/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/pivot/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/pivot/phone4.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/pivot/phone5.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/pivot/opp-desk.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body7: file(relativePath: { eq: "projects/pivot/prof-desk.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body8: file(relativePath: { eq: "projects/pivot/funky.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
